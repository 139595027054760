@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,500&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");

.body {
  font-family: "Source Sans Pro", sans-serif;
}

//CONTAINER
.mainContainer {
  height: 100%;

  @media only screen and (max-width: 767px) {
    height: auto;
  }
}

//STEPS
.stepsLeft {
  background-color: #f7f7f7;
  height: 100%;

  @media only screen and (max-width: 767px) {
    height: auto;
  }

  .logo {
    max-width: 120px;
  }

  .progressBar {
    .progress {
      height: 6px;
      .progress-bar {
        background-color: #2c2363;
      }
    }

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #818181;
      margin-bottom: 5px;
    }

    h5 {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #1a1a1a;
      margin-bottom: 12px;
    }
  }

  .stepDisplay {
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #46454d;
      margin-bottom: 5px;

      @media only screen and (max-width: 991px) {
        margin-bottom: 10px;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #46454d;
      margin-bottom: 5px;

      @media only screen and (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
  }
}

//MAIN
.assessmentUI {
  padding: 80px 20px 40px 40px;

  @media only screen and (max-width: 991px) {
    padding: 20px;
  }

  .titQuestion h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 44px;
    color: #1a1a1a;
    padding-bottom: 30px;

    @media only screen and (max-width: 991px) {
      padding-bottom: 20px;
    }
  }

  .questioUI {
    padding-top: 20px;
    padding-bottom: 20px;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #0b091a;
      margin-bottom: 10px;
    }

    [type="date"] {
      background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 95% 50%
        no-repeat;
      border-radius: 10px;
      border: 1px solid #ccc;
      padding: 5px 10px;
      min-width: 400px;
      font-size: 16px;
      min-height: 36px;

      @media only screen and (max-width: 991px) {
        min-width: auto;
        width: 100%;
      }
    }
    [type="date"]::-webkit-inner-spin-button {
      display: none;
    }
    [type="date"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    [type="date"]:focus {
      outline: none !important;
      border: 1px solid #2c2363;
      box-shadow: 0 0 3px #2c2363;
    }

    [type="text"], [type="number"], [type="email"] {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #ccc;
      padding: 5px 10px;
      min-width: 300px;
      @media only screen and (max-width: 991px) {
        min-width: auto;
        width: 100%;
      }
    }

    [type="text"]:focus, [type="number"]:focus, [type="email"]:focus {
      outline: none !important;
      border: 1px solid #2c2363;
      box-shadow: 0 0 3px #2c2363;
    }

    select {
      background: #fff;
      border-radius: 10px;
      border: 1px solid #ccc;
      padding: 5px 10px;
      min-width: 300px;
      @media only screen and (max-width: 991px) {
        min-width: auto;
        width: 100%;
      }
    }

    select:focus {
      outline: none !important;
      border: 1px solid #2c2363;
      box-shadow: 0 0 3px #2c2363;
    }

    [type="button"] {
      font-weight: 700;
      color: #2c2363;
      background-color: #fff;
      border: 1px solid #2c2363;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      min-height: 45px;
      border-radius: 25px;
      padding-left: 20px;
      padding-right: 20px;

      @media only screen and (max-width: 991px) {
        min-width: auto;
        width: 100%;
      }
    }

    [type="button"]:hover {
      font-weight: 700;
      color: #ffffff;
      background-color: #2c2363;
      border: 1px solid #2c2363;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 12px;
      color: #ffffff;
      min-height: 45px;
      border-radius: 25px;
      padding-left: 20px;
      padding-right: 20px;

      @media only screen and (max-width: 991px) {
        min-width: auto;
        width: 100%;
      }
    }

    [type="button"].active {
      font-weight: 700;
      color: #ffffff;
      background-color: #2c2363;
      border: 1px solid #2c2363;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 12px;
      color: #ffffff;
      min-height: 45px;
      border-radius: 25px;
      padding-left: 20px;
      padding-right: 20px;

      @media only screen and (max-width: 991px) {
        min-width: auto;
        width: 100%;
      }
    }
  }
}

.btn-transparent {
  background: transparent !important;
  color: #5c6166 !important;
  border: transparent !important;
  outline: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  min-height: 45px;
  font-weight: 600;
}

.btn-transparent:hover {
  color: #000 !important;
}

.btn-blue {
  font-weight: 700;
  color: #ffffff;
  background-color: #2c2363;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  min-height: 45px;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 14px;
  cursor: pointer;
  height: 100%;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active {
  color: #ffffff;
  background-color: #000;
  border: 0;
  cursor: pointer;
}

.btn-options {
  font-weight: 700;
  color: #0b091a;
  background-color: #f7f7f7;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  min-height: 45px;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: start;
}

.btn-options:hover,
.btn-options:focus,
.btn-options:active,
.btn-options.active {
  color: #ffffff;
  background-color: #2c2363;
  border: 0;
}

.undoButton {
  cursor: pointer;
}

.no-pointer {
  pointer-events: none !important;
}

.react-multiple-carousel__arrow {
  background: transparent !important;
}

.react-multiple-carousel__arrow::before {
  color: #2c2363 !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}

.swal2-styled.swal2-confirm {
  font-weight: 700 !important;
  color: #ffffff !important;
  background-color: #2c2363 !important;
  border: 0 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 12px !important;
  color: #ffffff !important;
  min-height: 45px !important;
  border-radius: 10px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;

  cursor: pointer !important;
  height: 45px !important;
}

.swal2-styled.swal2-confirm:hover {
  color: #ffffff !important;
  background-color: #000 !important;
  border: 0 !important;
  cursor: pointer !important;
}

.splashScreen {
  background-image: url("../../assets/splash-bg.svg");
  background-position: center center;
  background-size: cover;

  @media only screen and (max-width: 991px) {
    background-size: auto;
  }

  .logo {
    max-width: 200px;
    margin-bottom: 50px;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 74px; /* Color/Black */
    color: #0b091a;

    @media only screen and (max-width: 991px) {
      font-size: 44px;
      line-height: 54px; /* Color/Black */
    }
  }
}

.stepCarousel {
  .carousel-inner {
    height: 140px;
    .carousel-item {
      height: 140px;
      padding-top: 5px;
    }
  }
}


.btn-green-limber {
  background-color: #6e7d88 !important;
  box-shadow: 0 0 0 3px #6e7d88 !important;
}